import type {
  AppSectionEntry,
  ArticleEntry,
  ArticleFrontPageSectionEntry,
  BenefitsSectionEntry,
  ColumnMenuEntry,
  ContractHeroSectionEntry,
  ContractListSectionEntry,
  ContractProductEntry,
  CtaSectionEntry,
  EnterprisePageEntry,
  FaqSectionEntry,
  HeroSectionEntry,
  IconLinkEntry,
  IframeSectionEntry,
  ImageLinkEntry,
  InPageFaqSectionEntry,
  IvrSectionEntry,
  LinkEntry,
  LinkSectionEntry,
  MediaTestimonialSectionEntry,
  PageEntry,
  ProductDetailsSectionEntry,
  PromotedContractsSectionEntry,
  QuickSearchSectionEntry,
  QuizSectionEntry,
  QuoteSectionEntry,
  ReviewsSectionEntry,
  ServiceAnnouncementLoggedInEntry,
  ServiceAnnouncementOpenWebEntry,
  SpotPriceSectionEntry,
  StatisticsSectionEntry,
  SuggestedPagesSectionEntry,
  TextSectionEntry,
  UspSectionEntry,
} from '../types'

type Entry = {
  __typename: string
}

export const isTypeServiceAnnouncementLoggedIn = (
  entry: Entry,
): entry is ServiceAnnouncementLoggedInEntry => {
  return entry.__typename === 'ServiceAnnouncementLoggedIn'
}

export const isTypeServiceAnnouncementOpenWeb = (
  entry: Entry,
): entry is ServiceAnnouncementOpenWebEntry => {
  return entry.__typename === 'ServiceAnnouncement'
}

export const isTypeEnterprisePageEntry = (entry: Entry): entry is EnterprisePageEntry => {
  return entry.__typename === 'EnterprisePage'
}

export const isTypePageEntry = (entry: Entry): entry is PageEntry => {
  return entry.__typename === 'Page'
}

export const isTypeUspSection = (entry: Entry): entry is UspSectionEntry => {
  return entry.__typename === 'UspSection'
}

export const isArticleEntry = (entry: Entry): entry is ArticleEntry => {
  return entry.__typename === 'Article'
}

export const isTypeCtaSection = (entry: Entry): entry is CtaSectionEntry => {
  return entry.__typename === 'CtaSection'
}

export const isQuoteSection = (entry: Entry): entry is QuoteSectionEntry => {
  return entry.__typename === 'QuoteSection'
}

export const isMediaTestimonialSection = (entry: Entry): entry is MediaTestimonialSectionEntry => {
  return entry.__typename === 'MediaTestimonialSection'
}

export const isHeroSection = (entry: Entry): entry is HeroSectionEntry => {
  return entry.__typename === 'HeroSection'
}

export const isQuickSearchSection = (entry: Entry): entry is QuickSearchSectionEntry => {
  return entry.__typename === 'QuickSearchSection'
}

export const isReviewsSection = (entry: Entry): entry is ReviewsSectionEntry => {
  return entry.__typename === 'ReviewsSection'
}

export const isStatisticsSection = (entry: Entry): entry is StatisticsSectionEntry => {
  return entry.__typename === 'StatisticsSection'
}

export const isBenefitsSection = (entry: Entry): entry is BenefitsSectionEntry => {
  return entry.__typename === 'BenefitsSection'
}

export const isContractHeroSection = (entry: Entry): entry is ContractHeroSectionEntry => {
  return entry.__typename === 'ContractHeroSection'
}

export const isProductDetailsSection = (entry: Entry): entry is ProductDetailsSectionEntry => {
  return entry.__typename === 'ProductDetailsSection'
}

export const isContractListSection = (entry: Entry): entry is ContractListSectionEntry => {
  return entry.__typename === 'ContractListSection'
}

export const isSpotPriceSection = (entry: Entry): entry is SpotPriceSectionEntry => {
  return entry.__typename === 'SpotPriceSection'
}

export const isAppSection = (entry: Entry): entry is AppSectionEntry => {
  return entry.__typename === 'AppSection'
}

export const isInPageFaqSection = (entry: Entry): entry is InPageFaqSectionEntry => {
  return entry.__typename === 'InPageFaqSection'
}

export const isTextSection = (entry: Entry): entry is TextSectionEntry => {
  return entry.__typename === 'TextSection'
}

export const isPromotedContractsSection = (
  entry: Entry,
): entry is PromotedContractsSectionEntry => {
  return entry.__typename === 'PromotedContractsSection'
}

export const isSuggestedPagesSection = (entry: Entry): entry is SuggestedPagesSectionEntry => {
  return entry.__typename === 'SuggestedPagesSection'
}

export const isArticleFrontPageSection = (entry: Entry): entry is ArticleFrontPageSectionEntry => {
  return entry.__typename === 'ArticleFrontPageSection'
}

export const isFaqSection = (entry: Entry): entry is FaqSectionEntry => {
  return entry.__typename === 'FaqSection'
}

export const isIframeSection = (entry: Entry): entry is IframeSectionEntry => {
  return entry.__typename === 'IframeSection'
}

export const isLinkSection = (entry: Entry): entry is LinkSectionEntry => {
  return entry.__typename === 'LinkSection'
}

export const isTypeIconLink = (entry: Entry): entry is IconLinkEntry => {
  return entry.__typename === 'IconLink'
}

export const isTypeLink = (entry: Entry): entry is LinkEntry => {
  return entry.__typename === 'Link'
}

export const isTypeColumnMenu = (entry: Entry): entry is ColumnMenuEntry => {
  return entry.__typename === 'ColumnMenu'
}

export const isTypeImageLink = (entry: Entry): entry is ImageLinkEntry => {
  return entry.__typename === 'ImageLink'
}

export const isTypeContractProduct = (entry: Entry): entry is ContractProductEntry => {
  return entry.__typename === 'ContractProduct'
}

export const isIvrSection = (entry: Entry): entry is IvrSectionEntry => {
  return entry.__typename === 'IvrSection'
}

export const isQuizSection = (entry: Entry): entry is QuizSectionEntry => {
  return entry.__typename === 'QuizSection'
}
