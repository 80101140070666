import type { Tenant } from '@fortum/global-consent-components'

import type { AppEnv, Country } from './envs'
import { browserEnvs } from './envs'

type AppEnvWithOutLocal = Exclude<AppEnv, 'local'>

type Consent = {
  tenant: Tenant
  openWebTemplateId: Record<AppEnvWithOutLocal, string>
  /*
   * NOTE: We use CBUS QA environment in our dev environment
   */
  loggedInTemplateId: Record<AppEnvWithOutLocal, string>
}

export type CountryConfig = {
  basePath: string
  countryName: string
  targetSystem: string
  support: {
    phone: string
    formattedPhone: string
    email: string
  }
  features: {
    contractLegalText: boolean
  }
  publicApi: {
    smartCharging: string
  }
  localStoragePrefix: string
  customerServicePage?: string
  currencySign: string
  enterpriseApp: {
    prodUrl: string
    stagingUrl: string
  }
  legacyApp: {
    prodUrl: string
    stagingUrl: string
  }
  consent: Consent
  ciam: {
    clientIdPrd: string
    clientIdStg: string
    clientIdDev: string
    acrValue: string
  }
}

export const countryConfigs: { [k in Exclude<Country, 'GB'>]: CountryConfig } = {
  SE: {
    basePath: '/se/el',
    countryName: 'Sverige',
    targetSystem: 'SE-MS',
    support: {
      phone: '+4620460000',
      formattedPhone: '020-46 00 00',
      email: 'support@fortum.com',
    },
    features: {
      contractLegalText: false,
    },
    publicApi: {
      smartCharging: 'https://smartladdning.fortum.se/api/smart-charging/supported-models',
    },
    localStoragePrefix: 'gw-se.',
    customerServicePage: '/kundservice', // for finland and norway, this will be added after the contentful entry is ready
    currencySign: 'kr',
    enterpriseApp: {
      prodUrl: 'https://online.fortum.se',
      stagingUrl: 'https://eo-fortumsweden-feat-frontend-app.azurewebsites.net',
    },
    legacyApp: {
      prodUrl: 'https://www.mittfortum.se',
      stagingUrl: 'https://retail-lisa-eu-qa.herokuapp.com',
    },
    consent: {
      tenant: 'Fortum_Markets_SWEMI',
      openWebTemplateId: {
        dev: '65df4e723c36c101293877f5',
        stg: '6602ba3c352d5d7aad2caef9',
        prd: '664eda788506891c277f4ec3',
      },
      loggedInTemplateId: {
        dev: '6602ba64352d5d7aad2caefb',
        stg: '6602ba64352d5d7aad2caefb',
        prd: '664edb49c562c307bb09a370',
      },
    },
    ciam: {
      clientIdDev: 'globalwebdev',
      clientIdStg: 'globalwebstaging',
      clientIdPrd: 'globalwebprod',
      acrValue: 'seb2cogwlogin',
    },
  },
  FI: {
    basePath: '/fi/sahkoa',
    countryName: 'Suomi',
    targetSystem: 'FI-MS',
    support: {
      phone: '+35820019000',
      formattedPhone: '0200 19000',
      email: 'support@fortum.com',
    },
    features: {
      contractLegalText: false,
    },
    publicApi: {
      smartCharging: 'https://smart-charging-fi.linkapp.no/api/smart-charging/supported-models',
    },
    localStoragePrefix: 'gw-fi.',
    currencySign: '€',
    enterpriseApp: {
      prodUrl: 'https://online.fortum.fi',
      stagingUrl: 'https://eo-fortumfinland-feat-frontend-app.azurewebsites.net',
    },
    legacyApp: {
      prodUrl: 'https://web.fortum.fi',
      stagingUrl: 'https://retail-online-application-qa.herokuapp.com',
    },
    consent: {
      tenant: 'Fortum_Markets_FI',
      openWebTemplateId: {
        dev: '66fa54d28356fda408bee1b5',
        stg: '66fa6cf7ce350425a35848bf',
        prd: '670fb7389c438f6f88e43558',
      },
      loggedInTemplateId: {
        // TODO: add the correct template id, waiting for CoOp team to provide the correct template id
        dev: '67472c2fe04c4eb14f451e79',
        stg: '67472c2fe04c4eb14f451e79',
        prd: '67472d1bfd11c1a68082e6b2',
      },
    },
    ciam: {
      clientIdDev: 'globalwebdev_fi',
      clientIdStg: 'globalwebstaging_fi',
      clientIdPrd: 'globalwebprod_fi',
      acrValue: 'fib2cogwlogin',
    },
  },
  NO: {
    basePath: '/no/strom',
    countryName: 'Norge',
    targetSystem: 'NO-MS',
    support: {
      phone: '+4721494949',
      formattedPhone: '21 49 49 49',
      email: 'support@fortum.com',
    },
    features: {
      contractLegalText: true,
    },
    publicApi: {
      smartCharging: 'https://minside.fortum.no/api/smart-charging/supported-models',
    },
    localStoragePrefix: 'gw-no.',
    currencySign: 'kr',
    enterpriseApp: {
      prodUrl: 'https://online.fortum.no',
      stagingUrl: 'https://eo-fortumstrom-feat-frontend-app.azurewebsites.net',
    },
    legacyApp: {
      prodUrl: '',
      stagingUrl: '',
    },
    consent: {
      tenant: 'Fortum_Markets_SWEMI',
      // TODO: add the correct template id
      openWebTemplateId: {
        dev: '',
        stg: '',
        prd: '',
      },
      loggedInTemplateId: {
        dev: '',
        stg: '',
        prd: '',
      },
    },
    ciam: {
      clientIdDev: 'globalwebdev_no',
      clientIdStg: 'globalwebstaging_no',
      clientIdPrd: 'globalwebprod_no',
      acrValue: 'nob2cogwlogin',
    },
  },
}

/**
 * Get configuration that only depends on the country.
 * This configuration is available both in the client and server.
 * Do not include any hardcoded secrets here.
 */
export const getCountryConfig = (country: Country): CountryConfig => {
  switch (country) {
    case 'SE':
    case 'FI':
    case 'NO':
      return countryConfigs[country]
    default:
      return countryConfigs['SE']
  }
}

/**
 * Configuration for the current country. Available in the client.
 */
export const countryConfig = getCountryConfig(browserEnvs.NEXT_PUBLIC_COUNTRY)
export const allCountryConfigs = countryConfigs
